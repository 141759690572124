<template>
  <div>
    <div
      v-if="cargando"
      variant="success"
      class="mt-5 d-flex justify-content-center"
    >
      <b-spinner variant="success" label="Loading..."></b-spinner>
    </div>
    <div
      v-if="!cargando && estadoResultados === 4"
      class="cuadro_central mb-5 justify-content-md-center"
    >
      <div
        v-if="$parent.detalle == 0"
        class="justify-content-md-center"
        style="overflow:hidden ;position:relative;"
        v-bind:style="[
          window.height > 700
            ? window.height < 900 && $mq === 'mobile'
              ? { height: window.height - 220 + 'px' }
              : { height: window.height - 170 + 'px' }
            : { height: window.height - 140 + 'px' }
        ]"
      >
        <b-row class="ml-2 mt-5 mr-2">
          <!------------------------------------------------------------------->
          <div v-if="$mq === 'mobile' || window.height < 700">
            <b-row>
              <b-col
                style=" margin: -47px 0px 0px 0px; padding: 0px 0px 0px 0px; height: 30px;"
              ></b-col>
              <b-col
                style="text-align: right;  margin: -47px 10px 0px 0px; padding: 0px 0px 0px 0px; height: 30px;"
              >
                <b-button
                  v-b-toggle.menu-lateral
                  variant="success"
                  z-index="10000"
                  style="width: 30px; height: 30px;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px"
                  ><i class="fas fa-bars fa-xs" />
                </b-button>
              </b-col>
            </b-row>
            <b-sidebar
              ref="sidebar"
              id="menu-lateral"
              shadow
              z-index="100000"
              width="240px"
              backdrop
            >
              <b-col
                style="text-align: left;max-width: 250px;margin:0px 0px 0px 0px;padding:30px 50px 30px 10px;background-color:#efefef;  border-radius: 0px 20px 20px 0px;"
              >
                <b style="padding:0px 0px 0px 15px;">Características</b>
                <b-row class="mt-4">
                  <b-link
                    @click="cambioFiltro('todos')"
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2"
                        ><img
                          :src="getImgLogoUrl('menu_filtro_todos.png')"
                          style="width:20px;height:20px;"
                      /></b-col>
                      <b-col style="text-align:left;">Todos</b-col>
                      <b-col style="text-align:right;margin:0px 10px 0px 0px;">
                        {{ totalRasgosInforme() }}
                      </b-col>
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-2">
                  <b-link
                    @click="
                      cambioFiltro(getElementoPorNombre('Personalidad').nombre)
                    "
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="getImgLogoUrl('MB_icon_cat_psicologia.png')"
                          style="width:20px;height:20px;"
                      /></b-col>
                      <b-col style="text-align:left;">{{
                        getElementoPorNombre('Personalidad').nombre
                      }}</b-col>
                      <b-col
                        style="text-align:right;;margin:0px 10px 0px 0px;"
                        >{{
                          getElementoPorNombre('Personalidad').rasgosInforme
                            .length
                        }}</b-col
                      >
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-2">
                  <b-link
                    @click="
                      cambioFiltro(getElementoPorNombre('Capacidades').nombre)
                    "
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="getImgLogoUrl('MB_icon_cat_capacidades.png')"
                          style="width:20px;height:20px;"
                      /></b-col>
                      <b-col style="text-align:left;">{{
                        getElementoPorNombre('Capacidades').nombre
                      }}</b-col>
                      <b-col
                        style="text-align:right;margin:0px 10px 0px 0px;"
                        >{{
                          getElementoPorNombre('Capacidades').rasgosInforme
                            .length
                        }}</b-col
                      >
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-2">
                  <b-link
                    @click="cambioFiltro(getElementoPorNombre('Trastornos').nombre)"
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="getImgLogoUrl('MB_icon_cat_sueño.png')"
                          style="width:20px;height:20px;"
                      /></b-col>
                      <b-col style="text-align:left;">{{
                        getElementoPorNombre('Trastornos').nombre
                      }}</b-col>
                      <b-col
                        style="text-align:right;margin:0px 10px 0px 0px;"
                        >{{
                          getElementoPorNombre('Trastornos').rasgosInforme.length
                        }}</b-col
                      >
                    </b-row>
                  </b-link>
                </b-row>
                <!--<b-row class="mt-2">
                  <b-link
                    @click="
                      cambioFiltro(getElementoPorNombre('Adicciones').nombre)
                    "
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="getImgLogoUrl('MB_icon_cat_adicciones.png')"
                          style="width:20px;height:20px;"
                      /></b-col>
                      <b-col style="text-align:left;"
                        >{{ getElementoPorNombre('Adicciones').nombre }}
                      </b-col>
                      <b-col
                        style="text-align:right;margin:0px 10px 0px 0px;"
                        >{{
                          getElementoPorNombre('Adicciones').rasgosInforme
                            .length
                        }}</b-col
                      >
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-2 mb-5">
                  <b-link
                    @click="
                      cambioFiltro(getElementoPorNombre('Curiosidades').nombre)
                    "
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="getImgLogoUrl('MB_icon_cat_curiosidades.png')"
                          style="width:20px;height:20px;"
                      /></b-col>
                      <b-col style="text-align:left;">{{
                        getElementoPorNombre('Curiosidades').nombre
                      }}</b-col>
                      <b-col
                        style="text-align:right;margin:0px 10px 0px 0px;"
                        >{{
                          getElementoPorNombre('Curiosidades').rasgosInforme
                            .length
                        }}</b-col
                      >
                    </b-row>
                  </b-link>
                </b-row>-->

                <b class="mt-5" style="padding:0px 0px 0px 15px;">Opciones</b>
                <b-row class="mt-4">
                  <b-link
                    @click="pintarPantalla()"
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="getImgLogoUrl('MB_icon_cat_descargar PDF.png')"
                          style="width:20px;height:20px;"
                        />
                      </b-col>
                      <b-col style="text-align:left;">Descarga PDF</b-col>
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-4">
                  <b-link
                    @click="descargarFicheroSecuenciacion()"
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="
                            getImgLogoUrl('MB_icon_cat_descargar ADN RAW.png')
                          "
                          style="width:20px;height:20px;"
                      /></b-col>

                      <b-col style="text-align:left;">Descargar ADN RAW</b-col>
                    </b-row>
                  </b-link>
                </b-row>
              </b-col>
            </b-sidebar>
          </div>
          <!------------------------------------------------------------------------------------------------->
          <b-col
            v-bind:style="{ height: window.height - 230 + 'px' }"
            class="menuCarac"
            v-if="$mq != 'mobile' && window.height > 700"
          >
            <b style="padding:0px 0px 0px 15px;">Características</b>
            <b-row class="mt-4">
              <b-link
                @click="cambioFiltro('todos')"
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col class="ml-2" cols="1">
                    <img
                      :src="getImgLogoUrl('menu_filtro_todos.png')"
                      style="width:20px;height:20px;"
                    />
                  </b-col>

                  <b-col style="text-align:left;">Todos</b-col>
                  <b-col style="text-align:right;margin:0px 10px 0px 0px;">
                    {{ totalRasgosInforme() }}
                  </b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-2">
              <b-link
                @click="cambioFiltro(getElementoPorNombre('Personalidad').nombre)"
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img
                      :src="getImgLogoUrl('MB_icon_cat_psicologia.png')"
                      style="width:20px;height:20px;"
                    />
                  </b-col>
                  <b-col style="text-align:left;">{{
                    getElementoPorNombre('Personalidad').nombre
                  }}</b-col>
                  <b-col style="text-align:right;;margin:0px 10px 0px 0px;">{{
                    getElementoPorNombre('Personalidad').rasgosInforme.length
                  }}</b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-2">
              <b-link
                @click="
                  cambioFiltro(getElementoPorNombre('Capacidades').nombre)
                "
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img
                      :src="getImgLogoUrl('MB_icon_cat_capacidades.png')"
                      style="width:20px;height:20px;"
                    />
                  </b-col>
                  <b-col style="text-align:left;">{{
                    getElementoPorNombre('Capacidades').nombre
                  }}</b-col>
                  <b-col style="text-align:right;margin:0px 10px 0px 0px;">{{
                    getElementoPorNombre('Capacidades').rasgosInforme.length
                  }}</b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-2">
              <b-link
                @click="cambioFiltro(getElementoPorNombre('Trastornos').nombre)"
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img
                      :src="getImgLogoUrl('MB_icon_cat_sueño.png')"
                      style="width:20px;height:20px;"
                    />
                  </b-col>
                  <b-col style="text-align:left;">{{
                    getElementoPorNombre('Trastornos').nombre
                  }}</b-col>
                  <b-col style="text-align:right;margin:0px 10px 0px 0px;">{{
                    getElementoPorNombre('Trastornos').rasgosInforme.length
                  }}</b-col>
                </b-row>
              </b-link>
            </b-row>
            <!--<b-row class="mt-2">
              <b-link
                @click="cambioFiltro(getElementoPorNombre('Adicciones').nombre)"
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img
                      :src="getImgLogoUrl('MB_icon_cat_adicciones.png')"
                      style="width:20px;height:20px;"
                    />
                  </b-col>
                  <b-col style="text-align:left;"
                    >{{ getElementoPorNombre('Adicciones').nombre }}
                  </b-col>
                  <b-col style="text-align:right;margin:0px 10px 0px 0px;">{{
                    getElementoPorNombre('Adicciones').rasgosInforme.length
                  }}</b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-2 mb-5">
              <b-link
                @click="
                  cambioFiltro(getElementoPorNombre('Curiosidades').nombre)
                "
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2"
                    ><img
                      :src="getImgLogoUrl('MB_icon_cat_curiosidades.png')"
                      style="width:20px;height:20px;"
                  /></b-col>
                  <b-col style="text-align:left;">{{
                    getElementoPorNombre('Curiosidades').nombre
                  }}</b-col>
                  <b-col style="text-align:right;margin:0px 10px 0px 0px;">{{
                    getElementoPorNombre('Curiosidades').rasgosInforme.length
                  }}</b-col>
                </b-row>
              </b-link>
            </b-row>-->

            <b class="mt-5" style="padding:0px 0px 0px 15px;">Opciones</b>
            <b-row class="mt-4">
              <b-link
                disabled
                @click="pintarPantallaNoMovil()"
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img
                      :src="getImgLogoUrl('MB_icon_cat_descargar PDF.png')"
                      style="width:20px;height:20px;"
                    />
                  </b-col>
                  <b-col style="text-align:left;">Descarga PDF</b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-4">
              <b-link
                disabled
                @click="descargarFicheroSecuenciacion()"
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img
                      :src="getImgLogoUrl('MB_icon_cat_descargar ADN RAW.png')"
                      style="width:20px;height:20px;"
                  /></b-col>
                  <b-col style="text-align:left;">Descargar ADN RAW</b-col>
                </b-row>
              </b-link>
            </b-row>
          </b-col>
          <!------------------------------------------------------------------------------------------------------------------------->
          <!------------------------------------------------------------------------------------------------------------------------->
          <!------------------------------------------------------------------------------------------------------------------------->

          <b-col>
            <div
              v-bind:style="[
                window.height > 700
                  ? $mq === 'mobile'
                    ? { height: window.height - 270 + 'px' }
                    : { height: '100%' }
                  : { height: window.height - 180 + 'px' }
              ]"
              v-bind:class="[
                window.height > 700
                  ? $mq === 'mobile'
                    ? 'listaRasgosP'
                    : 'listaRasgosG'
                  : 'listaRasgosP'
              ]"
            >
              <div
                style="width:100%;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;"
                v-for="rasgo in listadoRasgos"
                :key="rasgo.id"
                no-body
              >
                <div
                  style="width:90%;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;"
                  v-if="
                    rasgo.nombreGrupo == filtro ||
                      (filtro == 'todos' && rasgo.nombreGrupo != 'NO_GROUP')
                  "
                >
                  <div>
                    <b-link
                      class="link-fila"
                      style=" max-height: 300px;"
                      @click="rasgoActivo(rasgo)"
                    >
                      <b-row>
                        <b-col style="max-width: 40px;" class="icono-movil">
                          <img
                            v-if="ObtenerValoracionRasgo(rasgo).score >= 2"
                            :src="
                              getImgLogoUrl('MB_icon_flechas informe 3 up.png')
                            "
                            style="width:40px;height:40px;"
                            class=""
                          />
                          <img
                            v-if="
                              ObtenerValoracionRasgo(rasgo).score >= 1 &&
                                ObtenerValoracionRasgo(rasgo).score < 2
                            "
                            :src="
                              getImgLogoUrl('MB_icon_flechas informe 2 up.png')
                            "
                            style="width:40px;height:40px;"
                          />
                          <img
                            v-if="
                              ObtenerValoracionRasgo(rasgo).score === 0 ||
                                ObtenerValoracionRasgo(rasgo).score === 'NaN'
                            "
                            :src="
                              getImgLogoUrl('MB_icon_flechas informe igual.png')
                            "
                            style="width:40px;height:40px;"
                          />
                          <img
                            v-if="
                              ObtenerValoracionRasgo(rasgo).score > 0 &&
                                ObtenerValoracionRasgo(rasgo).score < 1
                            "
                            :src="
                              getImgLogoUrl('MB_icon_flechas informe 1 up.png')
                            "
                            style="width:40px;height:40px;"
                          />
                          <img
                            v-if="ObtenerValoracionRasgo(rasgo).score <= -2"
                            :src="
                              getImgLogoUrl(
                                'MB_icon_flechas informe 3 down.png'
                              )
                            "
                            style="width:40px;height:40px;"
                          />
                          <img
                            v-if="
                              ObtenerValoracionRasgo(rasgo).score <= -1 &&
                                ObtenerValoracionRasgo(rasgo).score > -2
                            "
                            :src="
                              getImgLogoUrl(
                                'MB_icon_flechas informe 2 down.png'
                              )
                            "
                            style="width:40px;height:40px;"
                          />
                          <img
                            v-if="
                              ObtenerValoracionRasgo(rasgo).score < 0 &&
                                ObtenerValoracionRasgo(rasgo).score > -1
                            "
                            :src="
                              getImgLogoUrl(
                                'MB_icon_flechas informe 1 down.png'
                              )
                            "
                            style="width:40px;height:40px;"
                          />
                        </b-col>

                        <b-col
                          class="columna-izq"
                          v-if="$mq != 'mobile' && $mq != 'tablet'"
                          ><p class="texto-link">
                            {{ rasgo.cabecera }}
                          </p></b-col
                        >
                        <b-col
                          v-if="
                            $mq != 'mobile' &&
                              $mq != 'tablet' &&
                              $mq != 'laptop'
                          "
                          style="max-width: 10%;"
                        ></b-col>
                        <b-col
                          class="columna-der"
                          v-if="$mq != 'mobile' && $mq != 'tablet'"
                          ><p class="texto-link">
                            {{ rasgo.cabecera2 }}
                          </p>
                        </b-col>

                        <b-col v-if="$mq === 'mobile' || $mq === 'tablet'">
                          <b-row class="columna-izq-movil"
                            ><p class="texto-link" style="font-weight: bold;">
                              {{ rasgo.cabecera }}
                            </p></b-row
                          >
                          <b-row class="columna-der-movil"
                            ><p class="texto-link">
                              {{ rasgo.cabecera2 }}
                            </p>
                          </b-row>
                        </b-col>

                        <b-col
                          style="max-width: 40px; margin: 15px 0px 0px 0px;"
                        >
                          <div style="margin-top: -2px;">
                            <i class="fas fa-chevron-right fa-lg"></i>
                          </div>
                        </b-col>
                        <hr
                          style="height:1px;max-width:94%;border-width:0;color:gray;background-color:gray"
                        />
                      </b-row>
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="$parent.detalle == 1" class="div-detalle">
        <b-row class="row-boton-descarga-pdf">
          <button
            onclick="window.print()"
            variant="outline-success"
            class="btn-descarga"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm "
            ></span>
            <span
              ><i class="fas fa-file-download fa-1x mr-2"></i>Descargar
              PDF</span
            >
          </button>
        </b-row>
        <div class="detalle_">
          <b-row>
            <b-col class="columna-izq-cabecera mb-4 ml-2"
              ><img :src="getImgLogoUrl(rasgoAct.logo)" class="imagen-cabecera"
            /></b-col>
            <b-col class="mt-3 columna-der-cabecera">
              <b-row class="cabecera mt-4">{{ rasgoAct.cabecera }}</b-row>
              <b-row class="cuerpo mr-4" v-html="rasgoAct.textologo"></b-row>
            </b-col>
          </b-row>
        </div>

        <div class="cuadrotextoverde  mt-3">
          <p class="textoverde">
            {{ nombreUsuario }}, {{ rasgoAct.textoverde_a
            }}<span class="textoverdegrande"> {{ rasgoAct.textoverde_b }}</span
            ><span class="textoverde"> {{ rasgoAct.textoverde_c }}</span>
          </p>
        </div>
      </div>
      <div>
        <div
          v-show="$mq != 'mobile' && $mq != 'tablet' && $parent.detalle === 1"
          id="svgcontainer1"
          class="svg-principal"
        >
        <p class="cabecera mt-5"><center>Tu puntuación genética</center></p>
          <div v-if="$parent.detalle == 1" style="text-align: center">
            <i
              v-b-modal.modal-3
              style="color: #747474;"
              class="fa fa-info-circle fa-2x"
            ></i>
          </div>
        </div>
        <div
          v-show="
            ($mq === 'mobile' || $mq === 'tablet') && $parent.detalle === 1
          "
          id="svgcontainer2"
          class="svg-principal"
        >
        <p class="cabecera mt-5"><center>Tu puntuación genética</center></p>
          <div v-if="$parent.detalle == 1" style="text-align: center">
            <i
              v-b-modal.modal-3
              style="color: #747474;"
              class="fa fa-info-circle fa-2x"
            ></i>
          </div>
        </div>
      </div>

      <div v-if="$parent.detalle == 1" class="div-detalle">
        <p class="cabecera mt-5"><center>Tus variantes genéticas analizadas</center></p>
        <div class="mt-5 mb-5 numero-variante justify-content-center">
          <!--- MOBILE TABLE START --------------------------------------------------------------------------------------------------->
          <b-row class="mt-5" v-if="$mq === 'mobile' || $mq === 'tablet'">
            <b-col>
              <b-row class="fila-variante"
                ><p class="texto-variantes-arriba">
                  Variantes genéticas analizadas
                </p></b-row
              >
              <b-row
                ><p class="texto-variantes-abajo" style="color:#009245">
                  {{ totales }}
                </p></b-row
              >
            </b-col>
            <b-col>
              <b-row class="fila-variante"
                ><p class="texto-variantes-arriba">
                  Variantes genéticas con efecto positivo
                </p></b-row
              >
              <b-row
                ><p class="texto-variantes-abajo" style="color:#399A29">
                  {{ positivas }}
                </p></b-row
              >
            </b-col>
          </b-row>
          <b-row v-if="$mq === 'mobile' || $mq === 'tablet'">
            <b-col>
              <b-row class="fila-variante"
                ><p class="texto-variantes-arriba">
                  Variantes genéticas con efecto negativo
                </p></b-row
              >
              <b-row
                ><p class="texto-variantes-abajo" style="color:#8cc63f">
                  {{ negativas }}
                </p></b-row
              >
            </b-col>
            <b-col>
              <b-row class="fila-variante"
                ><p class="texto-variantes-arriba">
                  Variantes genéticas sin efecto
                </p></b-row
              >
              <b-row
                ><p class="texto-variantes-abajo" style="color:#D9E021">
                  {{ sinEfecto }}
                </p></b-row
              >
            </b-col>
          </b-row>

          <b-row
            class="justify-content-center"
            v-if="$mq != 'mobile' && $mq != 'tablet'"
          >
            <b-col>
              <b-row class="fila-variante"
                ><p class="texto-variantes-arriba">
                  Variantes genéticas analizadas
                </p></b-row
              >
              <b-row
                ><p class="texto-variantes-abajo" style="color:#009245">
                  {{ totales }}
                </p></b-row
              >
            </b-col>
            <b-col>
              <b-row class="fila-variante"
                ><p class="texto-variantes-arriba">
                  Variantes genéticas con efecto positivo
                </p></b-row
              >
              <b-row
                ><p class="texto-variantes-abajo" style="color:#399A29">
                  {{ positivas }}
                </p></b-row
              >
            </b-col>

            <b-col>
              <b-row class="fila-variante"
                ><p class="texto-variantes-arriba">
                  Variantes genéticas con efecto negativo
                </p></b-row
              >
              <b-row
                ><p class="texto-variantes-abajo" style="color:#8cc63f">
                  {{ negativas }}
                </p></b-row
              >
            </b-col>
            <b-col>
              <b-row class="fila-variante"
                ><p class="texto-variantes-arriba">
                  Variantes genéticas sin efecto
                </p></b-row
              >
              <b-row
                ><p class="texto-variantes-abajo" style="color:#D9E021">
                  {{ sinEfecto }}
                </p></b-row
              >
            </b-col>
          </b-row>
          <b-row class=" row-boton-variantes">
            <button
              v-b-modal.modal-1
              variant="outline-success"
              class="mb-5 btn-variantes"
            >
              <span
                ><i class="fas fa-columns fa-1x mr-2"></i>Ver mis variantes
                genéticas</span
              >
            </button>
          </b-row>
        </div>
        <!--- MOBILE TABLE END --------------------------------------------------------------------------------------------------->
        <p class="cabecera mt-5"><center>Heredabilidad del rasgo</center></p>
        <div style="text-align: center;margin-left: 20rem;" class="mr-5">
          <i
            v-b-modal.modal-2
            style="color: #747474;"
            class="fas fa-info-circle fa-3x"
          ></i>
        </div>
        <div class="mb-5">
          <Progress
            :value="heredabilidad"
            :strokeWidth="40"
            :radius="140"
            :transitionDuration="2000"
            strokeColor="#399a29"
            ><template v-slot:default>
              <div>
                <p
                  style="font-family: 'Montserrat', sans-serif;font-size: 5rem;text-align: center;
           "
                >
                  {{ heredabilidad + '%' }}
                </p>
              </div>
            </template>

            <template v-slot:footer>
              <b-row>
                <b-col cols="1" class="ml-2" style="padding:0px 0px;">
                  <i class="fas fa-circle fa-2x ml-3" style="color:#3c9c2c"></i>
                </b-col>
                <b-col>
                  <p class="texto-pie-progreso ml-3">
                    Aportación de la genética
                  </p>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="1" class="ml-2" style="padding:0px 0px;">
                  <i class="fas fa-circle fa-2x ml-3" style="color:#cbe3c3"></i>
                </b-col>
                <b-col>
                  <p class="texto-pie-progreso ml-3">
                    Aportación de otros factores
                  </p>
                </b-col>
              </b-row>
            </template>
          </Progress>
        </div>
        <div style="text-align: center;" class="mb-5">
          <img
            class="imagen-separador"
            style="width: 80%; text-align: center; margin-left: 10px;"
            src="../images/separador.png"
          />
        </div>
        <div class="detalle_">
          <b-row v-if="$mq != 'mobile' && $mq != 'tablet'">
            <b-row class="cabecera mt-5 ">{{ rasgoAct.pregunta1 }}</b-row>
            <b-row>
              <b-col
                class="cuerpo mt-3"
                v-html="rasgoAct.textopregunta1"
              ></b-col>
              <b-col class="columna_imagen_pregunta"
                ><img
                  :src="getImgLogoUrl(rasgoAct.imagenpregunta)"
                  width="350px"
              /></b-col>
            </b-row>
          </b-row>
          <b-row v-if="$mq === 'mobile' || $mq === 'tablet'">
            <b-row class="cabecera mt-5 ">{{ rasgoAct.pregunta1 }}</b-row>

            <b-row class="cuerpo mt-3" v-html="rasgoAct.textopregunta1"></b-row>
            <b-row class="mt-3 fila_imagen_pregunta"
              ><img
                class="imagen_pregunta"
                :src="getImgLogoUrl(rasgoAct.imagenpregunta)"
            /></b-row>
          </b-row>
          <b-row class="cabecera mt-5">{{ rasgoAct.pregunta2 }} </b-row>
          <b-row class="mt-3">
            <b-col class="cuerpo mb-4" v-html="rasgoAct.textopregunta2">
            </b-col>
          </b-row>

          <!-- Factores externos-------------------------->
          <div
            v-if="
              factoresExternos[0].cabecera != '' &&
                $mq != 'mobile' &&
                $mq != 'tablet'
            "
          >
            <b-row>
              <p class="cabecera mt-5" v-html="rasgoAct.factoresExternosTitulo"></p>
              <p class="cuerpo  mb-4" v-html="rasgoAct.factoresExternosTexto"></p>
            </b-row>
            <b-row class="ml-3">
              <b-col
                v-for="index in 2"
                :key="index"
                no-body
                style="width: 300px;"
                class="mb-4"
              >
                <b-row class="mb-4">
                  <b-col
                    style="max-width:150px;padding:0px 0px 60px 0px;margin: 0px 0px 0px -45px"
                  >
                    <img
                      :src="getImgLogoUrl(factoresExternos[index - 1].imagen)"
                      style="width: 150px"
                    />
                  </b-col>
                  <b-col>
                    <p
                      style="font-size: 100%;font-weight:bold; color: #747474;font-family: Montserrat, sans-serif;text-align: left;"
                    >
                      {{ factoresExternos[index - 1].cabecera }}
                    </p>
                    <p class="cuerpo-factor">
                      {{ factoresExternos[index - 1].texto }}
                    </p>
                  </b-col>
                  <b-col v-if="index % 2 != 0" cols="2"></b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-4 ml-3">
              <b-col
                v-for="index in 2"
                :key="index"
                no-body
                style="width: 300px;"
                class="mb-4"
              >
                <b-row class="mb-4">
                  <b-col
                    style="max-width:150px;padding:0px 0px 60px 0px;margin: 0px 0px 0px -45px"
                  >
                    <img
                      :src="getImgLogoUrl(factoresExternos[index + 1].imagen)"
                      style="width: 150px"
                    />
                  </b-col>
                  <b-col>
                    <p
                      style="font-size: 100%;font-weight:bold; color: #747474;font-family: Montserrat, sans-serif;text-align: left;"
                    >
                      {{ factoresExternos[index + 1].cabecera }}
                    </p>
                    <p class="cuerpo-factor">
                      {{ factoresExternos[index + 1].texto }}
                    </p>
                  </b-col>
                  <b-col v-if="index % 2 != 0" cols="2"></b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-4 ml-3">
              <b-col
                v-for="index in 2"
                :key="index"
                no-body
                style="width: 300px;"
                class="mb-4"
              >
                <b-row>
                  <b-col
                    style="max-width:150px;padding:0px 0px 60px 0px;margin: 0px 0px 0px -45px"
                  >
                    <img
                      :src="getImgLogoUrl(factoresExternos[index + 3].imagen)"
                      style="width: 150px"
                    />
                  </b-col>
                  <b-col>
                    <p
                      style="font-size: 100%;font-weight:bold; color: #747474;font-family: Montserrat, sans-serif;text-align: left;"
                    >
                      {{ factoresExternos[index + 3].cabecera }}
                    </p>
                    <p class="cuerpo-factor">
                      {{ factoresExternos[index + 3].texto }}
                    </p>
                  </b-col>
                  <b-col v-if="index % 2 != 0" cols="2"></b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-4 ml-2">
              <b-col
                v-for="index in 2"
                :key="index"
                no-body
                style="width: 300px;"
                class="mb-4"
              >
                <b-row>
                  <b-col
                    style="max-width:150px;padding:0px 0px 60px 0px;margin: 0px 0px 0px -45px"
                  >
                    <img
                      :src="getImgLogoUrl(factoresExternos[index + 5].imagen)"
                      style="width: 150px"
                    />
                  </b-col>
                  <b-col>
                    <p
                      style="font-size: 100%;font-weight:bold; color: #747474;font-family: Montserrat, sans-serif;text-align: left;"
                    >
                      {{ factoresExternos[index + 5].cabecera }}
                    </p>
                    <p class="cuerpo-factor">
                      {{ factoresExternos[index + 5].texto }}
                    </p>
                  </b-col>
                  <b-col v-if="index % 2 != 0" cols="2"></b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <b-row
            style=" text-align: center; margin:0px auto;padding:0px auto;"
            class="justify-content-center"
            v-if="
              factoresExternos[0].cabecera != '' &&
                ($mq === 'mobile' || $mq === 'tablet')
            "
          >
            <b-row>
              <p class="cabecera mt-5" v-html="rasgoAct.factoresExternosTitulo"></p>
              <p style="text-align: left;" v-html="rasgoAct.factoresExternosTexto"></p>
            </b-row>
            <b-row
              style=" text-align: center; margin:0px auto;padding:0px auto;"
              class="justify-content-center"
            >
              <b-row
                v-for="index in 8"
                :key="index"
                class="justify-content-center mb-5"
                style="margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;"
              >
                <b-row
                  style="text-align:center;margin:0px auto;padding:0px auto;"
                  class="justify-content-center"
                >
                  <img
                    :src="getImgLogoUrl(factoresExternos[index - 1].imagen)"
                    style="width: 150px"
                  />
                </b-row>
                <b-row style="margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;">
                  <p
                    style="font-size: 100%;font-weight:bold; color: #747474;font-family: Montserrat, sans-serif;text-align: center;"
                  >
                    {{ factoresExternos[index - 1].cabecera }}
                  </p>
                  <p class="cuerpo-factor">
                    {{ factoresExternos[index - 1].texto }}
                  </p>
                </b-row>
              </b-row>
            </b-row>
          </b-row>
          <!---------------------------------------------->
          <b-row class="cabecera mt-5"> ¿Sabías qué? </b-row>
          <b-row class="mt-3">
            <b-col class="cuerpo  mb-4" v-html="rasgoAct.sabiasQue"> </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col class="cuerpo">
              <p
                style="text-align: left; font-size: 100%;font-weight:bold; color: #747474;font-family: 'Montserrat', sans-serif;"
              >
                Referencias psicológicas
              </p>
              <p class="referencias mt-1" v-html="rasgoAct.biblioPsico"></p>

              <p
                style="text-align: left; font-size: 100%;font-weight:bold; color: #747474;font-family: 'Montserrat', sans-serif;"
              >
                Referencias genéticas
              </p>
              <p class="referencias mt-1" v-html="rasgoAct.bibliography"></p>
            </b-col>
          </b-row>
          <b-row>
            <b-col style="text-align: left; "
              ><b-button
                size="sm"
                variant="boton_"
                @click="volver()"
                class="mb-4 mr-0 mt-3"
                ><img
                  src="../images/resultados/back.png"
                  width="40vw"/></b-button
            ></b-col>
          </b-row>
        </div>
      </div>

      <b-modal id="modal-2" hide-footer centered size="xl">
        <p
          style="font-size: 120%;font-weight:bold; color: #747474;font-family: 'Montserrat', sans-serif;"
        >
          ¿Sabías qué?
        </p>
        <p
          style="font-size: 100%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;"
        >
          La mayoría de los rasgos que definen nuestra psicología están
          influenciados por múltiples factores diferentes, como la genética,
          estilo de vida y medio ambiente.
        </p>

        <p
          style="font-size: 100%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;"
        >
          Por lo general, un modelo estadístico que usa muchos factores
          proporciona mejores predicciones que mirar factores individuales por
          si mismos. Para desarrollar nuestros modelos, primero identificamos
          marcadores genéticos asociados con un rasgo utilizando datos de
          centenares de artículos cientificos que estudian la genética de la
          psicología humana. Luego usamos métodos estadísticos para generar un
          "puntuaje" o "score" para ese rasgo utilizando su gentipo en los
          marcadores genéticos relevantes.
        </p>
        <p
          style="font-size: 100%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;"
        >
          La precisión de estas predicciones varía de un rasgo a otro en función
          de la profundidad del estudio utilizado.
        </p>
      </b-modal>
      <b-modal id="modal-3" hide-footer centered size="xl">
        <!-- <p
          style="font-size: 110%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;"
        >
          Puntuación Máxima Adaptativa
        </p> -->
        <p
          style="font-size: 110%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;"
        >
          Puntuación de riesgo
        </p>
        <p
          style="font-size: 100%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;"
        >
          Este es el límite de puntuación obtenido en test psicológicos para
          determinar la puntuación normativa de la población en esta variable.
          Una mayor puntuación mayor de este límite implica el desarrollo poco
          habitual de la característica. Este es el límite de puntuación
          obtenido en test psicológicos para determinar la puntuación normativa
          de la población en esta variable. Una mayor puntuación mayor de este
          límite implica el desarrollo poco habitual de la característica.
        </p>
        <p
          style="font-size: 110%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;"
        >
          Puntuación Riesgo Poligénico
        </p>
        <p
          style="font-size: 100%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;"
        >
          Esta puntuación indica tu riesgo poligénico para el rasgo mostrado. Se
          trata de un cálculo realizado en base a las variantes genéticas que
          posees y su frecuencia en la población. Cuanto mayor es este índice
          mayor predisposición poseemos al rasgo y, cuanto menor es, menor
          predisposición poseemos. Esta puntuación indica tu riesgo poligénico
          para el rasgo mostrado. Se trata de un cálculo realizado en base a las
          variantes genéticas que posees y su frecuencia en la población. Cuanto
          mayor es este índice mayor predisposición poseemos al rasgo y, cuanto
          menor es, menor predisposición poseemos.
        </p>
      </b-modal>
      <b-modal id="modal-1" size="xl" class="modal-variantes">
        <b-row class="modal-variantes" style="font-weight: bold;">
          <b-col>RSID</b-col>
          <b-col>Cromosoma</b-col>
          <b-col>Posición</b-col>
          <b-col>Alelo de riesgo</b-col>
          <b-col>Genotipo</b-col>
        </b-row>
        <b-row
          class="modal-variantes"
          v-bind:style="[
            nodo.efecto < 0
              ? 'background-color:#feffd7'
              : nodo.efecto > 0
              ? 'background-color:#e6ffe6'
              : 'background-color:#ffffff'
          ]"
          v-for="nodo in variantesResultadoActivo"
          :key="nodo.id"
          no-body
        >
          <b-col>
            {{ nodo.rsid }}
          </b-col>
          <b-col>
            {{ nodo.chr }}
          </b-col>
          <b-col>
            {{ nodo.posicion }}
          </b-col>
          <b-col>
            {{ nodo.aleloRiesgo }}
          </b-col>
          <b-col>
            {{ nodo.genotipo }}
          </b-col>
        </b-row>
        <template v-slot:modal-title>
          <p style="font-weight: bold;">{{ titleModal }}</p>
        </template>
        <template v-slot:modal-footer>
          <b-col>
            <p
              style="background-color:#e6ffe6;text-align: center;"
              class="modal-variantes"
            >
              Variantes con efecto positivo
            </p>
          </b-col>
          <b-col>
            <p
              style="background-color:#feffd7;text-align: center;"
              class="modal-variantes"
            >
              Variantes con efecto negativo
            </p>
          </b-col>
          <b-col>
            <p
              style="background-color:#ffffff;text-align: center;"
              class="modal-variantes"
            >
              Variantes sin efecto
            </p>
          </b-col>
        </template>
      </b-modal>
    </div>

    <div
      v-if="$parent.detalle == 1"
      style="right: 0; 
        bottom: 0; 
        left: 0; 
        padding: 10px 0px 10px 0px; 
        background-color: #7bb021; 
        text-align: center;"
    >
      <center>
        <p style="color: #ffffff">
          <a href="https://mendelsbrain.com/aviso-legal/" style="color: #ffffff"
            >Términos legales</a
          >
          |
          <a
            href="https://mendelsbrain.com/politica-de-privacidad/"
            style="color: #ffffff"
            >Política de privacidad</a
          >
          |
          <a
            href="https://mendelsbrain.com/politica-de-cookies/"
            style="color: #ffffff"
            >Cookies</a
          >
          |
          <a
            href="https://mendelsbrain.com/consentimiento-informado/"
            style="color: #ffffff"
            >Consentimiento informado</a
          >
        </p>
        <p>
          <img
            width="290"
            class="etiqueta-ce"
            src="../images/MB_icono_label_footer_blanco.png"
            alt="Certificado CE"
          />
        </p>
      </center>
    </div>

    <div
      v-if="$parent.detalle == 0"
      style="position:fixed;
      right: 0; 
        bottom: 0; 
        left: 0; 
        padding: 0px 0px 0px 0px; 
        background-color: #7bb021; 
        text-align: center;"
    >
      <center>
        <p style="color: #ffffff">
          <a
            href="https://mendelsbrain.com/aviso-legal/"
            style="color: #ffffff; font-size: 16px;"
            >Términos legales</a
          >
          |
          <a
            href="https://mendelsbrain.com/politica-de-privacidad/"
            style="color: #ffffff; font-size: 16px;"
            >Política de privacidad</a
          >
          |
          <a
            href="https://mendelsbrain.com/politica-de-cookies/"
            style="color: #ffffff; font-size: 16px;"
            >Cookies</a
          >
          |
          <a
            href="https://mendelsbrain.com/consentimiento-informado/"
            style="color: #ffffff; font-size: 16px;"
            >Consentimiento informado</a
          >
          <img
            width="90"
            class="etiqueta-ce"
            src="../images/MB_icono_label_footer_blanco.png"
            alt="Certificado CE"
          />
        </p>
      </center>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Progress from 'easy-circular-progress';
import * as d3 from 'd3';
import resultadosFake from '../resources/resultado_fake.json';
import informeFake from '../resources/informe_fake.json';

export default {
  name: 'Admin',

  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      wheight: 'red',
      listadoRasgos: [],
      cargando: false,
      nombreUsuario: '',
      codigoKit: '',
      usuario: '',
      detalle: this.$parent.detalle,
      loading: '',
      titleModal: '',
      estadoResultados: 4,
      //valoracion_espanol: [],
      valoracion_ingles: [],
      /*valoracion_espanol: [
        { id: 1, tipo: 'menor' },
        { id: 2, tipo: 'menor' },
        { id: 3, tipo: 'menor' },
        { id: 4, tipo: 'menor' },
        { id: 5, tipo: 'menor' },
        { id: 7, tipo: 'menor' }
      ],*/
      valoracion_espanol: [],
      variantesResultadoActivo: [],
      variantesResultado: [],
      valoresResultado: [],
      heredabilidad: 0,
      /*variantesResultado: [
        {
          id: 1,
          id_trait_master: 5,
          rsid: 'rs1234',
          chr: '9',
          posicion: '123456',
          aleloRiesgo: 'A',
          genotipo: 'AC',
          efecto: '1'
        },
        {
          id: 1,
          id_trait_master: 5,
          rsid: 'rs1234',
          chr: '9',
          posicion: '123456',
          aleloRiesgo: 'A',
          genotipo: 'AC',
          efecto: '0'
        },
        {
          id: 1,
          id_trait_master: 5,
          rsid: 'rs1234',
          chr: '9',
          posicion: '123456',
          aleloRiesgo: 'A',
          genotipo: 'AC',
          efecto: '-1'
        }
      ],*/
      positivas: 0,
      negativas: 0,
      sinEfecto: 0,
      totales: 0,
      /*
      valoracion_ingles: [
        { id: 26, tipo: 'mayor' },
        { id: 27, tipo: 'igual' },
        { id: 28, tipo: 'menor' },
        { id: 29, tipo: 'igual' },
        { id: 30, tipo: 'igual' },
        { id: 31, tipo: 'igual' },
        { id: 32, tipo: 'mayor' },
        { id: 33, tipo: 'igual' },
        { id: 34, tipo: 'menor' },
        { id: 35, tipo: 'igual' },
        { id: 36, tipo: 'mayor' },
        { id: 37, tipo: 'mayor' },
        { id: 38, tipo: 'igual' },
        { id: 39, tipo: 'mayor' },
        { id: 40, tipo: 'menor' },
        { id: 41, tipo: 'igual' },
        { id: 42, tipo: 'mayor' },
        { id: 43, tipo: 'igual' },
        { id: 44, tipo: 'mayor' }
      ],*/
      frase_porc_espanol:
        'La variabilidad del rasgo en una población que se debe a diferencias genéticas entre personas',
      frase_porc_ingles:
        'The variability of the trait in a population that is due to genetic differences between people',
      frase_porc:
        'La variabilidad del rasgo en una población que se debe a diferencias genéticas entre personas',

      frase_cab_espanol1:
        '"La ciencia, para mí, da una explicación parcial de la vida. En la medida en que se basa en hechos, experiencias y experimentos.',
      frase_cab_espanol2:
        'La ciencia y la vida cotidiana no pueden ni deben separarse "',
      frase_cab_espanol3: 'Rosalind Franklin.',
      frase_cab_espanol4:
        'La genética juega un papel importante en la personalidad. Sin embargo, la influencia de nuestro entorno y el trabajo personal en nuestra personalidad es clara. Aquí estudiamos la personalidad con respecto a tu genética.',
      frase_cab_espanol5:
        'Recomendamos que trates tus resultados genéticos con el mismo cuidado que lo hacemos nosotros. Al fin y al cabo, un gran poder conlleva una gran responsabilidad.',

      frase_cab_ingles:
        '"Science, for me, gives a partial explanation for life. In so far as it goes, it is based on fact, experience and experiment.' +
        'Science and everyday life cannot and should not be separated."' +
        'Rosalind Franklin' +
        'Genetics play an important role in personality. However, the influence of our environment and personal work on our personality is clear. Here we study personality with respect to your genetics.' +
        'We recommend that you treat your genetic results with the same care that we do. After all, with great power comes great responsibility.',

      frase_cab1:
        '"La ciencia, para mí, da una explicación parcial de la vida. En la medida en que se basa en hechos, experiencias y experimentos.',
      frase_cab2:
        'La ciencia y la vida cotidiana no pueden ni deben separarse "',
      frase_cab3: 'Rosalind Franklin.',
      frase_cab4:
        'La genética juega un papel importante en la personalidad. Sin embargo, la influencia de nuestro entorno y el trabajo personal en nuestra personalidad es clara. Aquí estudiamos la personalidad con respecto a tu genética.',
      frase_cab5:
        'Recomendamos que trates tus resultados genéticos con el mismo cuidado que lo hacemos nosotros. Al fin y al cabo, un gran poder conlleva una gran responsabilidad.',

      rasgoAct: [],
      rasgos: [],
      // rasgos_espanol: [],
      rasgos_ingles: [],
      filtro: 'todos',
      factoresExternos: []
      //GRAFICO
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getListadoRasgos() {
      this.listadoRasgos = [];
      for (let i = 0; i < this.rasgos.gruposResultado.length; i++) {
        var nombreGrupo = this.rasgos.gruposResultado[i].nombre;
        for (
          let j = 0;
          j < this.rasgos.gruposResultado[i].rasgosInforme.length;
          j++
        ) {
          var rasgoLista = this.rasgos.gruposResultado[i].rasgosInforme[j];
          rasgoLista.nombreGrupo = nombreGrupo;
          this.listadoRasgos.push(rasgoLista);
        }
      }

      this.listadoRasgos.sort(function(a, b) {
        return b.norden - a.norden;
      });

      console.log(this.listadoRasgos);
    },
    getStyleMenu() {
      return {
        'background-color': '#333333'
      };
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    getEstadoResultados() {
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'http://localhost:9001/api/mendels/resultado/estado',
          {
            token: user.accessToken
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.estadoResultados = response.data.estado;
            this.$parent.estadoRes = response.data.estado;
            if (this.estadoResultados == 4) {
              this.obtenerValoracion('ESP');
            }
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    pintarPantalla() {
      this.$refs.sidebar.hide();

      setTimeout(function() {
        window.print();
      }, 2000);
    },
    pintarPantallaNoMovil() {
      window.print();
    },
    getColor(valor) {
      if (valor > 0) {
        return '#399a29';
      } else {
        return '#c6cc33';
      }
    },
    factoresExternosFiltradoMenor(pos) {
      return this.factoresExternos.filter(obj => {
        obj.posicion < pos;
      });
    },
    factoresExternosFiltradoMayor(pos) {
      return this.factoresExternos.filter(obj => {
        obj.pos > pos;
      });
    },
    getElementoPorNombre(nombre) {
      var result = this.rasgos.gruposResultado.filter(obj => {
        return obj.nombre === nombre;
      });
      if (result.length == 1) {
        return result[0];
      }
    },
    totalRasgosInforme() {
      var total = 0;
      if (Array.isArray(this.rasgos.gruposResultado)) {
        for (let i = 0; i < this.rasgos.gruposResultado.length; i++) {
          if (this.rasgos.gruposResultado[i].nombre != 'NO_GROUP') {
            total = total + this.rasgos.gruposResultado[i].rasgosInforme.length;
          }
        }
        return total;
      }
    },
    getScore(rasgo) {
      let valorRasgo = this.valoresResultado.filter(
        valorResultado => valorResultado.id_trait_master == rasgo.id_trait
      );

      if (valorRasgo[0].score <= 3 && valorRasgo[0].score >= -3) {
        return (valorRasgo[0].score * 100) / 3;
      } else if (valorRasgo[0].score < -3) {
        return -100;
      } else {
        return 100;
      }
    },
    getScoreComponente(score) {
      if (score < 0) {
        score = score * -1;
      }
      // Valor mínimo para pintar algo
      if (score < 0.3) {
        return 10;
      }
      if (score <= 3) {
        return (score * 100) / 3;
      } else if (score > 3) {
        return 100;
      } else if (score == 0) {
        return 0;
      }
    },

    descargarFicheroSecuenciacion() {
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'http://localhost:9001/api/mendels/archivo/usuario',
          {
            token: user.accessToken
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            const url = window.URL.createObjectURL(
              new Blob([response.data.fichero])
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              response.data.name + '.' + response.data.extension
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
          },
          error => {
            console.log(error);
          }
        );
    },
    cambioFiltro(valor) {
      this.filtro = valor;
    },
    rasgoActivo(rasgo) {
      this.rasgoAct = rasgo;
      this.titleModal =
        'Mis variantes genéticas para ' + this.rasgoAct.cabecera;
      this.variantesResultadoActivo = [];
      this.positivas = 0;
      this.negativas = 0;
      this.sinEfecto = 0;
      this.totales = 0;

      for (var i = 0; i < this.variantesResultado.length; i++) {
        if (
          this.variantesResultado[i].id_trait_master == this.rasgoAct.id_trait
        ) {
          this.variantesResultadoActivo.push(this.variantesResultado[i]);
        }
      }

      for (var j = 0; j < this.variantesResultadoActivo.length; j++) {
        if (this.variantesResultadoActivo[j].efecto == 1) {
          this.positivas++;
          this.totales++;
        }
        if (this.variantesResultadoActivo[j].efecto == 0) {
          this.sinEfecto++;
          this.totales++;
        }
        if (this.variantesResultadoActivo[j].efecto == -1) {
          this.negativas++;
          this.totales++;
        }

        // Obtener factores Externos
        this.factoresExternos = [];
        var pos = 0;
        if (this.rasgoAct.edad != '') {
          pos++;
          this.factoresExternos.push({
            posicion: pos,
            cabecera: 'Edad',
            imagen: 'MB_icon_FE 8.png',
            texto: this.rasgoAct.edad
          });
        }

        if (this.rasgoAct.sucesosCriticos != '') {
          pos++;
          this.factoresExternos.push({
            posicion: pos,
            cabecera: 'Sucesos criticos',
            imagen: 'MB_icon_FE 4.png',
            texto: this.rasgoAct.sucesosCriticos
          });
        }
        if (this.rasgoAct.entornoFamiliar != '') {
          pos++;
          this.factoresExternos.push({
            posicion: pos,
            cabecera: 'Entorno Familiar',
            imagen: 'MB_icon_FE 1.png',
            texto: this.rasgoAct.entornoFamiliar
          });
        }
        if (this.rasgoAct.relacionesSociales != '') {
          pos++;
          this.factoresExternos.push({
            posicion: pos,
            cabecera: 'Relaciones sociales',
            imagen: 'MB_icon_FE 5.png',
            texto: this.rasgoAct.relacionesSociales
          });
        }
        if (this.rasgoAct.salud != '') {
          pos++;
          this.factoresExternos.push({
            posicion: pos,
            cabecera: 'Salud',
            imagen: 'MB_icon_FE 3.png',
            texto: this.rasgoAct.salud
          });
        }
        if (this.rasgoAct.factoresInternos != '') {
          pos++;
          this.factoresExternos.push({
            posicion: pos,
            cabecera: 'Factores Internos',
            imagen: 'MB_icon_FE 6.png',
            texto: this.rasgoAct.factoresInternos
          });
        }
        if (this.rasgoAct.factoresSocioecon != '') {
          pos++;
          this.factoresExternos.push({
            posicion: pos,
            cabecera: 'Factores socio-económicos',
            imagen: 'MB_icon_FE 2.png',
            texto: this.rasgoAct.factoresSocioecon
          });
        }
        if (this.rasgoAct.habits != '') {
          pos++;
          this.factoresExternos.push({
            posicion: pos,
            cabecera: 'Hábitos',
            imagen: 'MB_icon_FE 7.png',
            texto: this.rasgoAct.habits
          });
        }
        for (let ind = this.factoresExternos.length; ind < 8; ind++) {
          pos++;
          this.factoresExternos.push({
            posicion: pos,
            cabecera: '',
            imagen: '',
            texto: ''
          });
        }
      }

      this.heredabilidad = this.valoresResultado.filter(
        valorResultado => valorResultado.id_trait_master == rasgo.id_trait
      )[0].heredabilidad;

      this.$parent.detalle = 1
;

      let valorRasgo = this.ObtenerValoracionRasgo(rasgo);
      this.InitSVG2(380, 300, 'svgcontainer2', valorRasgo);
      this.InitSVG1(800, 400, 'svgcontainer1', valorRasgo);
    },
    ObtenerValoracionRasgo(valor) {
      let valorRasgo = this.valoresResultado.filter(
        valorResultado => valorResultado.id_trait_master == valor.id_trait
      );
      return valorRasgo[0];
    },
    volver() {
      this.$parent.detalle = 0;
      this.rasgoAct = [];
      d3.select('svg').remove();
    },
    getImgUrl(tipo) {
      if (tipo == 'igual') {
        return require('../images/resultados/igual.png');
      }
      if (tipo == 'mayor') {
        return require('../images/resultados/arriba.png');
      }
      if (tipo == 'menor') {
        return require('../images/resultados/abajo.png');
      }
    },
    getImgLogoUrl(url) {
      if (url != '') {
        return require('../images/resultados/' + url);
      }
    },
    modalResultadoRasgo(button) {
      this.$root.$emit('bv::show::modal', this.ResRasModal.id, button);
    },
    obtenerValoracion(language) {
      this.busy = true;
      this.cargando = true;
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'http://localhost:9001/api/mendels/resultado',
          {
            token: user.accessToken,
            language: language
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            if (language == 'ESP') {
              this.nombreUsuario = response.data.nombreUsuario;
              this.codigoKit = response.data.codigoKit;
              this.valoracion_espanol = response.data.valoracion;
              this.variantesResultado = response.data.variantesResultado;
              this.valoresResultado = response.data.valoresResultado;
              this.obtenerInforme_esp();
            }
            if (language == 'ENG') {
              this.valoracion_ingles = response.data.valoracion;
              this.obtenerInforme_ing();
            }
            this.busy = false;
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    obtenerInforme_esp() {
      this.busy = true;
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'http://localhost:9001/api/mendels/informe',
          {
            valoracion: this.valoracion_espanol
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            //this.rasgos_espanol = response.data;

            this.rasgos = response.data;
            this.getListadoRasgos();
            this.busy = false;
            this.cargando = false;
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    obtenerInforme_ing() {
      this.busy = true;
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'http://localhost:9001/api/mendels/informe',
          {
            valoracion: this.valoracion_ingles
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.rasgos_ingles = response.data;
            this.busy = false;
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    // GRÁFICOS
    mostrarModal() {
      this.$root.$emit('bv::show::modal', 'modal-1');
    },
    InitSVG1(ancho, alto, contenedor, valor) {
      d3.select('#' + contenedor)
        .select('svg')
        .remove();
      var svg1 = d3
        .select('#' + contenedor)
        .append('svg')
        .attr('width', ancho)
        .attr('height', alto)
        .style('display', 'block')
        .style('margin', 'auto');
      var defs1 = svg1.append('defs');

      var dropShadowFilter1 = defs1
        .append('svg:filter')
        .attr('id', 'drop-shadow1')
        .attr('filterUnits', 'userSpaceOnUse')
        .attr('width', '130%')
        .attr('height', '130%');
      dropShadowFilter1
        .append('svg:feGaussianBlur')
        .attr('in', 'SourceGraphic')
        .attr('stdDeviation', 5)
        .attr('result', 'blur-out');
      dropShadowFilter1
        .append('svg:feColorMatrix')
        .attr('in', 'blur-out')
        .attr('type', 'hueRotate')
        .attr('values', 180)
        .attr('result', 'color-out');
      dropShadowFilter1
        .append('svg:feOffset')
        .attr('in', 'color-out')
        .attr('dx', 3)
        .attr('dy', 3)
        .attr('result', 'the-shadow');
      dropShadowFilter1
        .append('svg:feBlend')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'the-shadow')
        .attr('mode', 'normal');
      // Base
      svg1
        .append('rect')
        .attr('y', 40)
        .attr('x', 50)
        .attr('width', ancho - 100)
        .attr('height', 40)
        .attr('rx', 20)
        .style('fill', '#eceaea')
        .style('filter', 'url(#drop-shadow1)')
        .style('stroke-width', 14);

      console.log('Score:' + valor.score);
      console.log('Calculo:' + this.getScoreComponente(valor.score));

      var barra_x1 = ancho / 2;
      var barra_ancho =
        (((ancho - 100) / 2) * this.getScoreComponente(valor.score)) / 100;

      var round_top_left = 0;
      var round_top_right = 0;
      var round_bottom_left = 0;
      var round_bottom_right = 0;
      var color = '';

      if (valor.score > 0) {
        round_top_left = 0;
        round_top_right = 1;
        round_bottom_left = 0;
        round_bottom_right = 1;
        color = '#8caf36';
      } else if (valor.score < 0) {
        barra_x1 =
          ancho / 2 -
          (((ancho - 100) / 2) * this.getScoreComponente(valor.score)) / 100;
        round_top_left = 1;
        round_top_right = 0;
        round_bottom_left = 1;
        round_bottom_right = 0;
        color = '#c6cc33';
      }

      /*x: x-coordinate
      y: y-coordinate
      w: width
      h: height
      r: corner radius
      tl: top_left rounded?
      tr: top_right rounded?
      bl: bottom_left rounded?
      br: bottom_right rounded?*/
      svg1
        .append('path')
        .attr(
          'd',
          this.rounded_rect(
            barra_x1,
            40,
            barra_ancho,
            40,
            20,
            round_top_left,
            round_top_right,
            round_bottom_left,
            round_bottom_right
          )
        )
        .style('fill', function() {
          return color;
        });

      //Puntuacion genetica
      if (valor.score < 0) {
        svg1
          .append('image')
          .attr('x', barra_x1 - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function() {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      if (valor.score > 0) {
        svg1
          .append('image')
          .attr('x', ancho / 2 + barra_ancho - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function() {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      if (valor.score == 0) {
        svg1
          .append('image')
          .attr('x', ancho / 2 - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function() {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      //Linea central
      svg1
        .append('line')
        .attr('x1', ancho / 2)
        .attr('y1', 40)
        .attr('x2', ancho / 2)
        .attr('y2', 80)
        .attr('stroke', 'black')
        .style('stroke-width', 2);

      //Texto pie

      console.log(valor);
      var valor_score = Math.round(valor.score * 1000) / 1000;

      svg1
        .append('image')
        .attr('x', ancho / 2 - 6 - 125)
        .attr('y', 150)
        .attr('width', 30)
        .attr('height', 30)
        .attr('xlink:href', function() {
          return require('../images/resultados/Triangulo arriba.png');
        });
      svg1
        .append('text')
        .text('Tu Puntuación genética')
        .attr('x', ancho / 2 - 6)
        .attr('y', 170)
        .attr('fill', 'gray')
        .attr('dy', '0.35em')
        .attr('font-size', '15')
        .attr('font-weight', 350)
        .attr('font-family', 'Montserrat, sans-serif')
        .attr('text-anchor', 'middle');

      svg1
        .append('text')
        .text(valor_score)
        .attr('x', ancho / 2 + 130)
        .attr('y', 170)
        .attr('fill', 'gray')
        .attr('dy', '0.35em')
        .attr('font-size', '15')
        .attr('font-weight', 350)
        .attr('font-family', 'Montserrat, sans-serif')
        .attr('text-anchor', 'end');

      //Puntuación máxima adaptativa

      var maxAdap = valor.limite;
      console.log('Valor:' + maxAdap);
      var valorMaxAdap = 0;
      if (maxAdap > 0) {
        valorMaxAdap =
          ancho / 2 +
          (((ancho - 100) / 2) * this.getScoreComponente(maxAdap)) / 100;
      }
      if (maxAdap < 0) {
        valorMaxAdap =
          ancho / 2 -
          (((ancho - 100) / 2) * this.getScoreComponente(maxAdap)) / 100;
      }

      if (maxAdap != 0 && maxAdap != null) {
        svg1
          .append('image')
          .attr('x', valorMaxAdap - 15)
          .attr('y', 5)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function() {
            return require('../images/resultados/triangulo abajo.png');
          });

        svg1
          .append('line')
          .attr('x1', valorMaxAdap)
          .attr('y1', 38)
          .attr('x2', valorMaxAdap)
          .attr('y2', 82)
          .attr('stroke', '#c6cc33')
          .style('stroke-width', 1);

        svg1
          .append('image')
          .attr('x', ancho / 2 - 131)
          .attr('y', 200)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function() {
            return require('../images/resultados/triangulo abajo.png');
          });
        svg1
          .append('text')
          //.text('Puntuación máxima')
          .text('Puntuación de riesgo')
          .attr('x', ancho / 2 + 64)
          .attr('y', 220)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');

        svg1
          .append('text')
          //.text('adaptativa')
          .text('')
          .attr('x', ancho / 2 - 15)
          .attr('y', 240)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');
        var valor_adap = Math.round(maxAdap * 100) / 100;

        if (maxAdap > 3) {
          valor_adap = '3.0';
        }
        if (maxAdap < -3) {
          valor_adap = '-3.0';
        }
        if (valor_adap === 'NaN') {
          valor_adap = 0;
        }

        svg1
          .append('text')
          .text(valor_adap)
          .attr('x', ancho / 2 + 130)
          .attr('y', 220)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');
      }
    },

    /*x: x-coordinate
      y: y-coordinate
      w: width
      h: height
      r: corner radius
      tl: top_left rounded?
      tr: top_right rounded?
      bl: bottom_left rounded?
      br: bottom_right rounded?*/
    rounded_rect(x, y, w, h, r, tl, tr, bl, br) {
      var retval;
      retval = 'M' + (x + r) + ',' + y;
      retval += 'h' + (w - 2 * r);
      if (tr) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + r;
      } else {
        retval += 'h' + r;
        retval += 'v' + r;
      }
      retval += 'v' + (h - 2 * r);
      if (br) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + r;
      } else {
        retval += 'v' + r;
        retval += 'h' + -r;
      }
      retval += 'h' + (2 * r - w);
      if (bl) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + -r;
      } else {
        retval += 'h' + -r;
        retval += 'v' + -r;
      }
      retval += 'v' + (2 * r - h);
      if (tl) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + -r;
      } else {
        retval += 'v' + -r;
        retval += 'h' + r;
      }
      retval += 'z';
      return retval;
    },
    InitSVG2(ancho, alto, contenedor, valor) {
      d3.select('#' + contenedor)
        .select('svg')
        .remove();
      var svg2 = d3
        .select('#' + contenedor)
        .append('svg')
        .attr('width', ancho)
        .attr('height', alto)
        .style('display', 'block')
        .style('margin', 'auto');

      var defs = svg2.append('defs');

      var dropShadowFilter = defs
        .append('svg:filter')
        .attr('id', 'drop-shadow')
        .attr('filterUnits', 'userSpaceOnUse')
        .attr('width', '130%')
        .attr('height', '130%');
      dropShadowFilter
        .append('svg:feGaussianBlur')
        .attr('in', 'SourceGraphic')
        .attr('stdDeviation', 5)
        .attr('result', 'blur-out');
      dropShadowFilter
        .append('svg:feColorMatrix')
        .attr('in', 'blur-out')
        .attr('type', 'hueRotate')
        .attr('values', 180)
        .attr('result', 'color-out');
      dropShadowFilter
        .append('svg:feOffset')
        .attr('in', 'color-out')
        .attr('dx', 3)
        .attr('dy', 3)
        .attr('result', 'the-shadow');
      dropShadowFilter
        .append('svg:feBlend')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'the-shadow')
        .attr('mode', 'normal');
      // Base
      svg2
        .append('rect')
        .attr('y', 40)
        .attr('x', 50)
        .attr('width', ancho - 100)
        .attr('height', 40)
        .attr('rx', 20)
        .style('fill', '#eceaea')
        .style('filter', 'url(#drop-shadow)')
        .style('stroke-width', 14);

      console.log('Score:' + valor.score);
      console.log('Calculo:' + this.getScoreComponente(valor.score));

      var barra_x1 = ancho / 2;
      var barra_ancho =
        (((ancho - 100) / 2) * this.getScoreComponente(valor.score)) / 100;

      var round_top_left = 0;
      var round_top_right = 0;
      var round_bottom_left = 0;
      var round_bottom_right = 0;
      var color = '';

      if (valor.score > 0) {
        round_top_left = 0;
        round_top_right = 1;
        round_bottom_left = 0;
        round_bottom_right = 1;
        color = '#8caf36';
      } else if (valor.score < 0) {
        barra_x1 =
          ancho / 2 -
          (((ancho - 100) / 2) * this.getScoreComponente(valor.score)) / 100;
        round_top_left = 1;
        round_top_right = 0;
        round_bottom_left = 1;
        round_bottom_right = 0;
        color = '#c6cc33';
      }

      /*x: x-coordinate
      y: y-coordinate
      w: width
      h: height
      r: corner radius
      tl: top_left rounded?
      tr: top_right rounded?
      bl: bottom_left rounded?
      br: bottom_right rounded?*/
      svg2
        .append('path')
        .attr(
          'd',
          this.rounded_rect(
            barra_x1,
            40,
            barra_ancho,
            40,
            20,
            round_top_left,
            round_top_right,
            round_bottom_left,
            round_bottom_right
          )
        )
        .style('fill', function() {
          return color;
        });

      //Puntuacion genetica
      if (valor.score < 0) {
        svg2
          .append('image')
          .attr('x', barra_x1 - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function() {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      if (valor.score > 0) {
        svg2
          .append('image')
          .attr('x', ancho / 2 + barra_ancho - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function() {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      if (valor.score == 0) {
        svg2
          .append('image')
          .attr('x', ancho / 2 - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function() {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      //Linea central
      svg2
        .append('line')
        .attr('x1', ancho / 2)
        .attr('y1', 40)
        .attr('x2', ancho / 2)
        .attr('y2', 80)
        .attr('stroke', 'black')
        .style('stroke-width', 2);

      //Texto pie

      console.log(valor);
      var valor_score = Math.round(valor.score * 1000) / 1000;

      svg2
        .append('image')
        .attr('x', ancho / 2 - 6 - 125)
        .attr('y', 150)
        .attr('width', 30)
        .attr('height', 30)
        .attr('xlink:href', function() {
          return require('../images/resultados/Triangulo arriba.png');
        });
      svg2
        .append('text')
        .text('Tu Puntuación genética')
        .attr('x', ancho / 2 - 6)
        .attr('y', 170)
        .attr('fill', 'gray')
        .attr('dy', '0.35em')
        .attr('font-size', '15')
        .attr('font-weight', 350)
        .attr('font-family', 'Montserrat, sans-serif')
        .attr('text-anchor', 'middle');

      svg2
        .append('text')
        .text(valor_score)
        .attr('x', ancho / 2 + 130)
        .attr('y', 170)
        .attr('fill', 'gray')
        .attr('dy', '0.35em')
        .attr('font-size', '15')
        .attr('font-weight', 350)
        .attr('font-family', 'Montserrat, sans-serif')
        .attr('text-anchor', 'end');

      //Puntuación máxima adaptativa

      var maxAdap = valor.limite;

      var valorMaxAdap = 0;
      if (maxAdap > 0) {
        valorMaxAdap =
          ancho / 2 +
          (((ancho - 100) / 2) * this.getScoreComponente(maxAdap)) / 100;
      }
      if (maxAdap < 0) {
        valorMaxAdap =
          ancho / 2 -
          (((ancho - 100) / 2) * this.getScoreComponente(maxAdap)) / 100;
      }

      if (maxAdap != 0 && maxAdap != null) {
        svg2
          .append('image')
          .attr('x', valorMaxAdap - 15)
          .attr('y', 5)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function() {
            return require('../images/resultados/triangulo abajo.png');
          });

        svg2
          .append('line')
          .attr('x1', valorMaxAdap)
          .attr('y1', 38)
          .attr('x2', valorMaxAdap)
          .attr('y2', 82)
          .attr('stroke', '#c6cc33')
          .style('stroke-width', 1);

        svg2
          .append('image')
          .attr('x', ancho / 2 - 131)
          .attr('y', 200)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function() {
            return require('../images/resultados/triangulo abajo.png');
          });
        svg2
          .append('text')
          //.text('Puntuación máxima')
          .text('Puntuación de riesgo')
          .attr('x', ancho / 2 + 64)
          .attr('y', 220)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');

        svg2
          .append('text')
          //.text('adaptativa')
          .text('')
          .attr('x', ancho / 2 - 15)
          .attr('y', 240)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');
        var valor_adap = Math.round(maxAdap * 100) / 100;

        if (maxAdap > 3) {
          valor_adap = '3.0';
        }
        if (maxAdap < -3) {
          valor_adap = '-3.0';
        }

        if (valor_adap === 'NaN') {
          valor_adap = 0;
        }

        svg2
          .append('text')
          .text(valor_adap)
          .attr('x', ancho / 2 + 130)
          .attr('y', 220)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');
      }
    },
    ObtenerImagen(imagen) {
      return require('../images/resultados/' + imagen);
    },
    leerFake() {
      console.log(resultadosFake);
      console.log(informeFake);

      this.nombreUsuario = resultadosFake.nombreUsuario;
      this.codigoKit = resultadosFake.codigoKit;
      this.valoracion_espanol = resultadosFake.valoracion;
      this.variantesResultado = resultadosFake.variantesResultado;
      this.valoresResultado = resultadosFake.valoresResultado;

      this.rasgos = informeFake;
      this.getListadoRasgos();
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    //if (!this.currentUser) {
    //  this.$router.push('/login');
    //}
    //this.usuario = JSON.parse(localStorage.getItem('user'));
    //this.getEstadoResultados();
    /*this.nombreUsuario = response.data.nombreUsuario;
    this.codigoKit = response.data.codigoKit;
    this.valoracion_espanol = response.data.valoracion;
    this.variantesResultado = response.data.variantesResultado;
    this.valoresResultado = response.data.valoresResultado;
    this.rasgos = response.data;
    this.getListadoRasgos();*/
    this.leerFake();
  },

  components: { Progress }
};
</script>
<style scoped>
.listaRasgosP {
  overflow-y: scroll;
  width: 100%;
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.listaRasgosG {
  overflow-y: scroll;
  height: 100%;
  position: absolute;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.menuCarac {
  height: auto;
  background-color: #efefef;
  text-align: left;
  max-width: 250px;
  margin: 0px 0px 0px 0px;
  padding: 30px 50px 30px 10px;

  border-radius: 0px 20px 20px 0px;
}
.frase_cab_css1 {
  width: 60%;
  margin: 0 500px; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
  border-color: #ffffff;

  color: #222222;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 18px;
}
.frase_cab_css2 {
  width: 60%;
  margin: 0 500px; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
  border-color: #ffffff;
  color: #222222;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 18px;
}
.frase_cab_css3 {
  width: 60%;
  margin: 0 250px; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
  border-color: #ffffff;
  color: #111111;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 18px;
  text-align: right;
}
.frase_cab_css4 {
  width: 48%;

  margin: 0 500px; /* Added */
  float: none; /* Added */

  border-color: #ffffff;
  color: #555555;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 15px;
}
.frase_cab_css5 {
  width: 50%;

  margin: 0 500px; /* Added */
  float: none; /* Added */

  border-color: #ffffff;
  color: #888888;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 15px;
}

.imagen_principal {
  width: 100%;
}
.alineado-derecha {
  text-align: right;
}
.alineado-izquierda {
  text-align: right;
}
.container {
  max-width: 3000px;
  position: relative;
  background: #ffffff;
}

.cuadro_central {
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.nav-link {
  color: #aaaaaa;
  text-align: center;
}
.image-logo {
  text-align: center;
}
.nav {
  margin-top: 50px;
  width: 2000px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #aaaaaa;
  line-height: 1em;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
}
.parrafo {
  margin-top: 50px;
  width: 700px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #aaaaaa;
  line-height: 1em;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
}
p {
  color: #555555;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 18px;
}

.card {
  width: 60%;
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
  border-color: #ffffff;
}
.detalle_ {
  width: 80%;
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
  border-color: #ffffff;
}
.cabecera {
  color: #59a22e;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 40px;
  text-align: left;
}
.cuerpo {
  color: #555555;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 18px;
  text-align: left;
}
.textocheck {
  color: #555555;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 24px;
}
.porcentaje {
  color: #59a22e;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 33px;
  padding: 0.75rem 1.25rem;
}
.cuadrotextoverde {
  background-image: url(../images/resultados/fondo_texto_verde.png);
  background-size: 100%;
  width: 100%;
}
.textoverde {
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: white;
  padding: 0.75rem 1.25rem;
  min-height: 100%;
}
.textoverdegrande {
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: white;

  min-height: 100%;
}
.header h1 {
  display: inline;
}

.referencias {
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 14px;
  text-align: left;
}
.boton_ {
  color: #ffffff;
  border-color: #ffffff;
}
@media all and (orientation: portrait) {
  .principal {
    width: 1080px;
    height: 3000px;
  }
  .card {
    width: 90%;
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
    border-color: #ffffff;
  }
}
/*------------------------------------------------------------------------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

listado_rasgos {
  overflow-y: scroll;
  height: 70vh;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.columna-izq {
  max-width: 300px;
  margin: 10px 0px 0px 20px;
}
.columna-der {
  max-width: 550px;
  margin: 10px 0px 0px 20px;
  text-align: right;
  padding: 0px 0px 0px 0px;
}
.columna-izq-movil {
  max-width: 300px;
  margin: 0px 0px 0px 0px;
  font-weight: bold;
  text-align: left;
}
.columna-der-movil {
  max-width: 550px;
  margin: 0px 0px 0px 0px;
  text-align: left;
  padding: 0px 0px 0px 0px;
}
.btn-menu {
  font-family: 'Montserrat', sans-serif;
  color: #399a29;
  background-color: #ffffff00;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #399a29;
  border-radius: 9999px 9999px 9999px 9999px;
  padding: 0px 0px 0px 0px;
  margin: 0px 20px 20px 20px;
  font-size: 12px;
  width: 170px;
  height: 50px;
}
.btn-menu:hover {
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  padding: 10px 20px;
  font-weight: bold;
  color: #ffffff;
  border-color: #399a29;
  background-color: #399a29;
}
.texto-link {
  font-family: 'Montserrat', sans-serif;
  color: #747474;
  font-size: 14px;
  margin: 0px 0px 5px 0px;
  padding: 0px 0px 0px 0px;
}

.columna_imagen_pregunta {
  max-width: 350px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 20px;
}
.imagen-cabecera {
  margin: 40px 20px 0px 0px;
  max-width: 100px;
  text-align: right;
}
.btn-descarga-secuenciacion {
  font-family: 'Montserrat', sans-serif;
  color: #399a29;
  background-color: #ffffff00;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #399a29;
  border-radius: 9999px 9999px 9999px 9999px;
  padding: 10px 0px 10px 0px;
  margin: 100px 0px 0px 0px;
  font-size: 15px;
  font-weight: bold;
  width: 400px;
}
.btn-descarga-secuenciacion:hover {
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  padding: 10px 20px;
  font-weight: bold;
  color: #ffffff;
  border-color: #399a29;
  background-color: #399a29;
}
.btn-descarga {
  font-family: 'Montserrat', sans-serif;
  color: #399a29;
  background-color: #ffffff00;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #399a29;
  border-radius: 9999px 9999px 9999px 9999px;
  padding: 10px 0px 10px 0px;
  margin: 0px 0px 0px 0px;
  font-size: 15px;
  font-weight: bold;
  width: 200px;
}
.btn-descarga:hover {
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  padding: 10px 20px;
  font-weight: bold;
  color: #ffffff;
  border-color: #399a29;
  background-color: #399a29;
}
.btn-variantes {
  font-family: 'Montserrat', sans-serif;
  color: #399a29;
  background-color: #ffffff00;
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-color: #399a29;
  border-radius: 9999px 9999px 9999px 9999px;
  padding: 10px 20px;
  margin: 30px 0px 20px 0px;
  font-size: 15px;
  font-weight: bold;
  width: 350px;
}
.btn-variantes:hover {
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  padding: 10px 20px;
  font-weight: bold;
  color: #ffffff;
  border-color: #399a29;
  background-color: #399a29;
}
.link-menu {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  border-radius: 0px 30px 30px 0px;
  border-style: solid;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 5px;
  color: #252424;
  border-color: #efefef;
  background-color: #efefef;
  text-decoration: none;
}
.link-menu:hover {
  border-radius: 0px 30px 30px 0px;
  border-style: solid;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 5px;
  color: #ffffff;
  border-color: #a5d89d;
  background-color: #a5d89d;
  text-decoration: none;
}

.modal-variantes {
  color: #747474;
  font-family: 'Montserrat', sans-serif;
  height: 3rem;
  text-align: center;
  vertical-align: auto;
}
.texto-rasgos {
  font-family: 'Montserrat', sans-serif;
  color: #747474;
  font-size: 22px;
}

.texto-pie-progreso {
  font-family: 'Montserrat', sans-serif;
  color: #747474;
  font-size: 20px;
  text-align: left;
}
.texto-variantes-arriba {
  color: #757575;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0px 10px 0px 10px;
  padding: 0px 30px 0px 30px;
}
.texto-variantes-abajo {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 90px;
  margin: 0px 10px 0px 10px;
  padding: 0px 20px 0px 20px;
}
.fila-variante {
  height: 40px;
}
.alineado-derecha {
  text-align: right;
}

.boton-flecha-derecha {
  color: #747474;
  background-color: #ffffff;
  border-color: #ffffff;
}
.link-fila {
  color: #747474;
  align-content: center;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  font-size: 10px;
}
.link-fila:hover {
  text-decoration: none;
  color: #222222;
  border-radius: 25px 25px;
  box-shadow: #747474;
  border-style: solid;
  border-color: #222222;
}
.link-fila-menu {
  color: #747474;
}
.link-fila-menu:hover {
  text-decoration: none;
}
.link-row {
  padding: 10px 0px 15px 20px;
  margin: 0px 0px 0px 200px;
  align-content: center;
}

.link-row:hover {
  text-decoration: none;
  color: #747474;
  border-radius: 30px 30px 30px 30px;
  box-shadow: #747474;
  border-color: #747474;
  box-shadow: 0px 0px 3px 0px;
  padding: 10px 0px 15px 20px;
}
.circular-progress {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3d147e;
  display: flex;
}
.circular-progress-interior {
  text-decoration: none;
  color: #222222;
  border-radius: 2000px;
  box-shadow: #222222;
  border-style: none;
  border-color: #222222;
  box-shadow: 0px 0px 25px 0px;
  width: 45px;
  height: 45px;
  margin: 23px;
}
.row-menu {
  text-align: center;
  width: 30%;
  padding: 0px 0px 0px 0px;
  margin: 50px auto 100px auto /* Added */;
  float: none; /* Added */
  border-color: #ffffff;
}
.div-detalle {
  text-align: center;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  justify-content: center;
}
.numero-variante {
  width: 90%;
  justify-content: center;
  margin: 0px auto;
  padding: 0px auto;
}
.card-rasgos {
  width: 40%;
  padding: 0px 0px 0px 0px;
  margin: 0 auto; /* Added */
  float: none; /* Added */
  border-color: #ffffff;
  text-align: center;
  align-content: center;
}
.columna-rasgos {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.row-boton-descarga-pdf {
  justify-content: end;
  margin: 0px 0px 0px 0px;
  padding: 0px 40px 0px 0px;
}
.row-boton-variantes {
  justify-content: center;
  margin: 0px 0px 90px 0px;
}
.modal-dialog {
  width: 400px;
}
/*------------------------------------------------------------------------------------------------------------------------------------*/
@media all and (max-width: 450px) {
  .icono-movil {
    padding: 0px 0px 0px 10px;
  }
  .texto-variantes-abajo {
    font-size: 70px;
  }
  .col-var-arriba {
    margin: 0px 0px 0px 0px;
    max-width: 50%;
    text-align: center;
  }
  .fila-var-abajo {
    margin: 0px 0px 0px 0px;
    max-width: 50%;
  }
  .imagen-cabecera {
    margin: 40px 0px 0px 0px;
    max-width: 100px;
    text-align: right;
  }
  .imagen-factor-externo {
    margin: 0px 0px 0px 0px;
    width: 60px;
    text-align: left;
  }
  .card-rasgos {
    width: 100%;
    padding: 0px 0px 0px 0px;
    border-color: #ffffff;
  }
  .row-menu {
    font-size: 90%;
  }
  .columna-rasgos {
    font-size: 80%;
    width: 5rem;
  }
  .row-boton-descarga-pdf {
    justify-content: center;

    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .textoverde {
    font-size: 100%;
  }
  .textoverdegrande {
    font-size: 100%;
  }
  .columna-izq-cabecera {
    max-width: 30%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
  .columna-der-cabecera {
    max-width: 70%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
  .alineado-derecha {
    text-align: center;
  }
  .texto-variantes-arriba {
    margin: 0px 0px 0px 0px;
    padding: 0px 20px 0px 20px;
    font-size: 100%;
  }
  .link-row {
    width: 100vw;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .rasgos {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
  .modal-variantes {
    font-size: 60%;
  }
  .cabecera {
    max-width: 90%;
    font-size: 25px;
    text-align: left;
    margin: 0px auto;
    padding: 0px auto;
  }

  .cuerpo {
    margin: 0px auto;
    padding: 0px auto;
    text-align: justify;
    font-size: 15px;
    max-width: 90%;
  }
  .cuerpo-factor {
    margin: 0px 0px 0px 0px;
    padding: 0px 10px 0px 10px;
    text-align: justify;
    font-size: 15px;
    max-width: 100%;
  }

  .detalle_ {
    width: 90%;
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
    border-color: #ffffff;
  }
  .fila_imagen_pregunta {
    justify-content: center;
    margin: 0px auto;
  }

  .numero-variante {
    font-size: 88%;
    width: 87%;
    justify-content: center;
    margin: 0px auto;
    padding: 0px auto;
  }
  .texto-link {
    font-family: 'Montserrat', sans-serif;
    color: #747474;
    font-size: 12px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
}

@media all and (min-width: 451px) and (max-width: 900px) {
  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .icono-movil {
    padding: 0px 0px 0px 10px;
  }
  .imagen_pregunta {
    width: 350px;
  }
  .texto-variantes-abajo {
    font-size: 70px;
    margin: 0px auto;
    padding: 0px auto;
  }
  .fila_imagen_pregunta {
    justify-content: center;
  }
  .col-var-arriba {
    margin: 0px 0px 0px 0px;
    max-width: 50%;
    text-align: center;
  }
  .fila-var-abajo {
    margin: 0px 0px 0px 0px;
    max-width: 50%;
  }
  .imagen-cabecera {
    margin: 40px 0px 0px 0px;
    max-width: 100px;
    text-align: right;
  }
  .imagen-factor-externo {
    margin: 0px 0px 0px 0px;
    width: 60px;
    text-align: left;
  }
  .card-rasgos {
    width: 90%;
    padding: 0px 0px 0px 0px;
    margin: 0 auto; /* Added */
    float: none; /* Added */
    border-color: #ffffff;
  }
  .row-menu {
    font-size: 90%;
  }
  .columna-rasgos {
    font-size: 80%;
  }
  .row-boton-descarga-pdf {
    justify-content: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .textoverde {
    font-size: 90%;
  }
  .textoverdegrande {
    font-size: 100%;
  }
  .columna-izq-cabecera {
    max-width: 30%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
  .columna-der-cabecera {
    max-width: 70%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
  .alineado-derecha {
    text-align: center;
  }
  .texto-variantes-arriba {
    margin: 0px auto;
    padding: 0px auto;
    font-size: 90%;
  }
  .cabecera {
    max-width: 90%;
    font-size: 30px;
    text-align: left;
    margin: 0px auto;
    padding: 0px auto;
  }

  .cuerpo {
    margin: 0px auto;
    padding: 0px auto;
    text-align: justify;
    font-size: 15px;
    max-width: 90%;
  }

  .modal-variantes {
    font-size: 60%;
  }

  .columna-rasgos {
    font-size: 80%;
  }
  .link-row {
    width: 90vw;
    margin: 0px 0px 0px 0px;
    padding: 0px auto;
  }
  .rasgos {
    width: 90vw;
    justify-content: center;
    align-items: center;
  }
  .link-row:hover {
    text-decoration: none;
    color: #747474;
    border-radius: 30px 30px 30px 30px;
    box-shadow: #747474;
    border-color: #747474;
    box-shadow: 0px 0px 3px 0px;
    padding: 10px 0px 15px 20px;
  }
  .card-rasgos {
    width: 90%;
    padding: 0px 0px 0px 0px;
    border-color: #ffffff;
  }
  .detalle_ {
    width: 90%;
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
    border-color: #ffffff;
  }
  .numero-variante {
    font-size: 90%;
    width: 90%;
    justify-content: center;
    margin: 0px auto;
    padding: 0px auto;
  }
}
@media all and (min-width: 451px) and (max-width: 620px) {
  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .cuerpo-factor {
    margin: 0px auto;
    padding: 0px auto;
    text-align: justify;
    font-size: 15px;
    max-width: 100%;
  }
  .texto-link {
    font-family: 'Montserrat', sans-serif;
    color: #747474;
    font-size: 12px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
}
@media all and (min-width: 620px) and (max-width: 900px) {
  .icono-movil {
    padding: 0px 0px 0px 10px;
  }
  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .cuerpo-factor {
    margin: 0px auto;
    padding: 0px 25px 0px 25px;
    font-size: 15px;
    max-width: 100%;
  }
  .texto-link {
    font-family: 'Montserrat', sans-serif;
    color: #747474;
    font-size: 12px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
}
/*------------------------------------------------------------------------------------------------------------------------------------*/
@media all and (min-width: 901px) and (max-width: 1223px) {
  .icono-movil {
    padding: 0px 0px 0px 10px;
  }
  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .columna-izq {
    max-width: 230px;
    margin: 10px 0px 0px 10px;
  }
  .columna-der {
    max-width: 550px;
    margin: 10px 0px 0px 20px;
    text-align: right;
    padding: 0px 0px 0px 0px;
  }
  .texto-link {
    font-family: 'Montserrat', sans-serif;
    color: #747474;
    font-size: 12px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .cuerpo-factor {
    margin: 0px auto;
    padding: 0px 0px 0px 0px;
    font-size: 15px;
    max-width: 100%;
    text-align: left;
  }
  .cuerpo {
    font-size: 15px;
  }
  .fila_imagen_pregunta {
    justify-content: center;
  }
  .texto-variantes-arriba {
    font-size: 90%;
  }
  .rasgos {
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .texto-variantes-arriba {
    font-size: 90%;
  }
  .textoverde {
    font-size: 100%;
  }
  .textoverdegrande {
    font-size: 100%;
  }
  .card-rasgos {
    width: 60%;
    padding: 0px 0px 0px 0px;
    margin: 0 auto; /* Added */
    float: none; /* Added */
    border-color: #ffffff;
  }
  .row-menu {
    font-size: 90%;
  }
  .columna-rasgos {
    font-size: 80%;
    width: 13rem;
  }
  .link-row {
    width: 650px;
    margin: 0px 0px 0px 0px;
  }

  .modal-variantes {
    font-size: 70%;
  }
  .columna-izq-cabecera {
    max-width: 35%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
  .columna-der-cabecera {
    max-width: 65%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
}
/*------------------------------------------------------------------------------------------------------------------------------------*/
/* Ordenadores de sobremesa y portátiles */
@media only screen and (min-width: 1224px) {
  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .card-rasgos {
    width: 80%;
    padding: 0px 0px 0px 0px;
    margin: 0 auto; /* Added */
    float: none; /* Added */
    border-color: #ffffff;
  }
  .columna-izq-cabecera {
    max-width: 40%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
  .columna-der-cabecera {
    max-width: 60%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
  .rasgos {
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .fila_imagen_pregunta {
    justify-content: center;
  }
  .cuerpo-factor {
    margin: 0px auto;
    padding: 0px 0px 0px 0px;
    font-size: 17px;
    max-width: 100%;
    text-align: left;
  }
}

/* Pantallas grandes */
@media only screen and (min-width: 1824px) {
  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .card-rasgos {
    width: 70%;
    padding: 0px 0px 0px 0px;
    margin: 0 auto; /* Added */
    float: none; /* Added */
    border-color: #ffffff;
  }
  .cuerpo-factor {
    margin: 0px auto;
    padding: 0px 0px 0px 0px;
    font-size: 17px;
    max-width: 100%;
    text-align: left;
  }
  .columna-izq-cabecera {
    max-width: 40%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
  .columna-der-cabecera {
    max-width: 60%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
  .fila_imagen_pregunta {
    justify-content: center;
  }
}
</style>
